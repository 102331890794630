<template>
  <div>
    <b-table
      :fields="fields"
      :items="get_admin_users_logs()"
      bordered
      class="break"
      empty-text="No Record Found"
      fixed
      hover
      id="user_logs_table"
      responsive
      show-empty
      small
      striped
    >
      <template  v-slot:cell(raw_request)="cell">
        <span v-b-popover.hover.right :title="cell.item.raw_request">
          {{ trim_raw_request(cell.item.raw_request) }}
        </span>
      </template>
      <template  v-slot:cell(raw_response)="cell">
        <span v-b-popover.hover.right :title="cell.item.raw_response">
          {{ trim_raw_request(cell.item.raw_response) }}
        </span>
      </template>
      <template  v-slot:cell(browser)="cell">
        <span>{{ admin_users_logs_info(cell, "browser") }}</span>
      </template>
      <template  v-slot:cell(device)="cell">
        <span>{{ admin_users_logs_info(cell, "device") }}</span>
      </template>
      <template v-slot:cell(platform)="cell">
        <span>{{ admin_users_logs_info(cell, "platform") }}</span>
      </template>
      <template  v-slot:cell(ip_address)="cell">
        <span>{{ admin_users_logs_info(cell, "ip_address") }}</span>
      </template>
      <template v-slot:cell(user_agent)="cell">
        <span>{{ admin_users_logs_info(cell, "user_agent") }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "AdminUserLogs",
  data() {
    return {
      fields: [
       {
         key :'admin_id',
          label: "id",
          thStyle: {
            width: "57px"
          }
        },
         {
           key :'raw_request',
          sortable: true,
          thStyle: {
            width: "200px"
          }
        },
        {
          key:'raw_response',
          sortable: true,
          thStyle: {
            width: "200px"
          }
        },
        {
          key:'ip_address',
          sortable: true,
          thStyle: {
            width: "78px"
          }
        },
        {
          key:'user_agent',
          thStyle: {
            width: "150px"
          }
        },
        {
          key:'device',
          thStyle: {
            width: "76px"
          }
        },
        {
          key:'browser',
          thStyle: {
            width: "67px"
          }
        },
        {
          key:'platform',
          thStyle: {
            width: "65px"
          }
        },
        {
          key:'is_phone',
          thStyle: {
            width: "53px"
          }
        },
        {
          key:'is_desktop',
          thStyle: {
            width: "63px"
          }
        },
        {
          key:'is_robot',
          thStyle: {
            width: "48px"
          }
        },
        {
          key:'created_at',
          thStyle: {
            width: "74px"
          }
        },
        {
          key:'updated_at',
          thStyle: {
            width: "80px"
          }
        }
      ],
      users_logs: []
    };
  },
  mounted() {
    this.get_admin_users_logs();
  },
  methods: {
    trim_raw_request(raw_request) {
      return raw_request.length > 40
        ? raw_request.slice(0, 40) + "..."
        : raw_request;
    },

    get_admin_users_logs() {
      return this.admins_logs = this.$store.state.admins_logs;
    },
    admin_users_logs_info(cell1, cell2) {
      if (cell2 === "browser") {
        return cell1.item.browser.name;
      } else if (cell2 === "device") {
        return cell1.item.device.name;
      } else if (cell2 === "platform") {
        return cell1.item.platform.name;
      } else if (cell2 === "ip_address") {
        return cell1.item.ip_address.name;
      } else if (cell2 === "user_agent") {
        return cell1.item.user_agent.name;
      }
    }
  }
};
</script>

<style scoped>
.break {
  max-height: 80vh;
  overflow: scroll;
}
</style>
<style>
.popover-header {
  padding: 10px !important;
  margin-bottom: -16px !important;
  font-size: 12px !important;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  width: 280% !important;
  heigt: 300vh !important;
}
</style>
